<template>
  <moe-page title="推送管理">
    <!-- 筛选信息 -->
    <moe-inquire @search="pushSearch">
      <el-form-item label="推文标题">
        <el-input v-model.trim="pushParams.title" placeholder="输入推文标题" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="推送方式">
        <moe-select type="sendSourceList" v-model="pushParams.sendSource" placeholder="选择推送方式" clearable />
      </el-form-item>
      <el-form-item label="消息类型">
        <moe-select type="pushTypeList" v-model="pushParams.pushType" placeholder="选择消息类型" clearable />
      </el-form-item>
      <el-form-item label="发送对象">
        <moe-select type="sendTargetList" v-model="pushParams.sendTarget" placeholder="选择发送对象" clearable />
      </el-form-item>
      <el-form-item label="推送时间">
        <el-date-picker
          style="width: 100%;"
          placement="bottom-start"
          v-model="datetime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="(datetime) => ([pushParams.pushTimeStart, pushParams.pushTimeEnd] = datetime || ['', ''])" />
      </el-form-item>
    </moe-inquire>

    <!-- 推送信息 -->
    <moe-table ref="pushTable" url="/message/pageList" :params="pushParams" :numberShow="false">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/pushManage/system/add')">推送添加</el-button>
      </template>
      <el-table-column prop="title" label="推文标题" min-width="150" show-overflow-tooltip />
      <el-table-column prop="content" label="推文内容" min-width="150" show-overflow-tooltip />
      <el-table-column prop="sendSource" label="推送方式" min-width="150">
        <template slot-scope="{ row }">{{ $moe_format.getSendSourceList(row.sendSource) }}</template>
      </el-table-column>
      <el-table-column prop="pushType" label="消息类型" min-width="150">
        <template slot-scope="{ row }">{{ $moe_format.getPushTypeList(row.pushType) }}</template>
      </el-table-column>
      <el-table-column prop="pushTime" label="推送时间" min-width="150" />
      <el-table-column prop="sendTarget" label="发送对象" min-width="150">
        <template slot-scope="{ row }">{{ $moe_format.getSendTargetList(row.sendTarget) }}</template>
      </el-table-column>
      <el-table-column prop="sendStatus" label="状态" min-width="150">
        <template slot-scope="{ row }">{{ $moe_format.getSendStatusList(row.sendStatus) }}</template>
      </el-table-column>
      <el-table-column prop="createUserName" label="操作人" min-width="150" />
      <el-table-column label="操作" fixed="right" min-width="150">
        <div class="moe-table_btns" slot-scope="{ row }">
          <el-button type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/pushManage/system/detail`, { id: row.id })">查看详情</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'PushList',
  data() {
    //推送筛选信息
    let pushParams = {
      pageNum: 1,
      pageSize: 10,
      title: '',
      sendSource: 'MANUAL',// 推送方式
      pushType: '',        // 消息类型
      sendTarget: '',      // 发送对象
      pushTimeStart: '',   // 推送开始时间
      pushTimeEnd: '',     // 推送结束时间
    };

    return {
      pushParams, //推送筛选信息
      datetime: [],
    };
  },
  methods: {
    /**
     * 推送搜索
     **/
    pushSearch(isSearch) {
      if (!isSearch) {
        this.pushParams = {
          pageNum: 1,
          pageSize: 10,
          title: '',
          sendSource: 'MANUAL',
          pushType: '',
          sendTarget: '',
          pushTimeStart: '',
          pushTimeEnd: '',
        };

        this.datetime = [];
      }

      //刷新推送表格
      this.$refs.pushTable.searchData();
    },
  },
};
</script>
